.App {
  text-align: center;
  display: flex;
  background-color: #7C7872;
  color: white;
  position:absolute;
  top:0;bottom:0;right:0;left:0;
  overflow-y: hidden;
}
.sidemenu {
  width: 20%;
  padding: 10px;
  background-color: white;
  border-right: 1px solid black;
}
.sidemenu h3 {
  font-size: 2.5rem;
  color: black;
}
.side-menu-button {
  background-color: rgba(255,255,255,0.0);
  text-align: center;
  color: black;;
  border: 1.2px solid black;;
  border-radius: 20px;
  transition:ease 0.25s all;
  font-size: 18px;
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 10px;
}
.side-menu-button:hover {
 border: 1px solid #7BAF73;
 color: #7BAF73;
 background-color: #7baf7317;
}
.chat-input-holder {
padding:2%;
position: absolute;
bottom: 0;
left:0; right:0;
background-color: #fefefe;
display: flex;
flex-direction: column;
border-top: 1px solid black;
overflow-y: hidden;
}

.chat-input-textarea {
  background-color: #FFFFFF;
  width: 64%;
  padding: 12px;
  color: #202123;
  font-size: 1em;
  border-radius: 15px;
  border: 1px solid #000000;
  margin: 10px;
  margin-bottom: 20px;
  outline: none;
  box-shadow: 0 0px 8px 0 rgba(0,0,0,0.1)
}
.submit-button {
  border: 1px solid #000000;
  height: 2.8em;
  width: 5em;
  padding: 10px;
  margin-left: 10px;
  box-shadow: 0 0px 8px 0 rgba(0,0,0,0.05);
  border-radius: 15px;
  transition: ease all .25s;
  background-color: #ffffff;
}
.submit-button:hover {
  background-color: #7BAF73;
  transition: ease all .25s;
  color: white;
}
.chatbox {
  flex: 3;
  background-color: #ffffff;
  position: relative;
}
.chat-log {
  text-align: left;
  height: 76.5%;
  overflow-y: scroll;
  padding-left: 8.5%;
  padding-right: 8.5%;
}
.chat-message {
  float: right;
  font-size: .8em;
  margin-top:20px;
  max-width: 80%;
  min-width: 70%;
  padding-left: 0px;
  background-color: rgba(221, 246, 228, 0.808);
  border: 1px solid #7BAF73;
  border-radius: 15px;
  padding: 2.0%;
}

.chat-message.chatgpt {
  background-color: rgba(221, 246, 228, 0.212);
  font-size: .8em;
  border-radius: 20px;
  float: left;
}
.chat-message-center {
  padding-left:0px;
  padding-right: 0px;
}
.avatar {
  border-radius: 0%;
  width: 40px;
  height: 40px;
}
.avatar.chatgpt {
  border-radius: 0%;
  width: 40px;
  height: 40px;
}
.message {
  padding-left:40px;
  padding-right: 40px;
  color: black;
}
.overlay-card {
  padding: 10px;
  border-radius: 8px;
  max-width: calc(70%);
  max-height: calc(100%);
  margin: auto;
  margin-top: 40px
}

.overlay-card-columns {
  display: flex;
}
.overlay-card-column {
  width: calc(100% / 3);
  padding: 10px;
  vertical-align: top;
  display: flex;
  flex-direction: column;
}
.overlay-card-column-example {
  width: calc(100% / 3);
  padding: 10px;
  vertical-align: top;
  display: flex;
  flex-direction: column;
}
.overlay-card-column:last-child {
  border-right: none;
}
.overlay-card h2 {
  margin-top: 0;
  margin-bottom: 25px;
  font-size: 30px;
  font-weight: bold;
  color: #4A4844;
}
.overlay-card h3 {
  margin-top: 0;
  margin-bottom: 25px;
  font-size: 20px;
  font-weight: bold;
  color: #4A4844;
}
.overlay-card p {
  font-weight: 300;
  font-size: 1rem;
  background-color: hsl(115, 100%, 98%);
  color: #4A4844;
  border: 1px solid #7BAF73;
  padding: 10px;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 10px;
}
.overlay-card-column-example p:hover {
  background-color: #7BAF73;
  color: white;
  transition: background-color 0.15s ease;
}
.below-chatbox {
  color:rgba(27, 32, 35, 0.431);
  font-size: 1em;
  padding-left: 5%;
  padding-right: 5%;
 }

 .live-info-toggle {
  background-color: #fafdae;
  border: none;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  font-size: 1.5em;
  color: #4A4844;
  transition: ease all .25s;
 }

  .live-info-toggle:hover {
    background-color: #f7eddd;
    transition: ease all .25s;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .slider-round {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
  }

  .slider-round:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }

  .live-info-toggle-text {
    color: #4A4844;
    font-size: 1.5em;
    margin-left: 10px;
  }

.search-live-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 10px;
  border-radius: 10px;
  background-color: #fafdae;
}

@media (max-width: 600px) {
.App {
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}

.sidemenu {
  width: 100%;
}
.sidemenu h3 {
display: none;
}
.side-menu-button {
  padding: 10px;
  width: 100%;
  border-radius: 10px;
}

.chat-input-textarea {
  margin-top: 0px;
  max-width: 50%;
  border-radius: 15px;
  font-size: .8em;
}
.submit-button {
  margin-top: 0px;
  max-width: 20%;
  border-radius: 10px;
  font-size: .8em;
}
.submit-button:hover {
  background-color: #7BAF73;
  transition: ease all .25s;
}
.chatbox {
  overflow-y: scroll;
}
.chat-log {
overflow-y: scroll;
}
.chat-message {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 15px;
}
.overlay-card {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.overlay-card h2 {
  font-size: 1.5rem;
}
.overlay-card h3 {
  font-size: 1.2rem;
}
.overlay-card p {
  font-size: 0.8rem;
  width: 100%;
  margin-top: 0px;
  padding: 8px;
  border-radius: 10px;
}
.below-chatbox {
  display: none;
 }
}

@media (min-width: 600px) and (max-width: 1030px) {
  .overlay-card p {
    font-size: 0.8rem;
    width: 100%;
    margin-top: 0px;
    padding: 10px;
    border-radius: 10px;
}
.overlay-card h3 {
  font-size: 1em;
}
.chat-input-textarea {
  margin-top: 10px;
  max-width: 55%;
  margin-bottom: 25px;
}
}

@media (max-width: 400px) {
  .overlay-card p { 
    font-size: .8em;
  }
  .submit-button {
    background-color: #20212300;
  }
}


@media (min-width: 600px) and (max-width: 650px) {
  .chat-input-textarea {
    margin-top: 10px;
    max-width: 55%;
    margin-bottom: 25px;
  }
  .below-chatbox {
    display: none;
  }
}
